import React, { FC } from 'react'

import { Layout } from '../old-components'
import { graphql, PageProps } from 'gatsby'
import { ILandingPageQuery } from '../types/landing-page'
import { Sections } from '../components/sections/sections'

type BikeRentalPageProps = PageProps<ILandingPageQuery>

const transformBikes = (sections) => {
  return sections.map((section) => {
    if (section.__typename !== 'ContentfulSectionRentalForm') return section
    const { bikes } = section.bikeSection
    return {
      ...section,
      bikes,
    }
  })
}

const BikeRentalPage: FC<BikeRentalPageProps> = ({ data }) => {
  const { contentfulLandingPage } = data
  const transformedSections = transformBikes(contentfulLandingPage.sections)
  return (
    <Layout title={contentfulLandingPage.pageTitle}>
      <Sections sections={transformedSections} />
    </Layout>
  )
}

export const query = graphql`
  query BikeRentalPageQuery($language: String) {
    contentfulLandingPage(
      slug: { eq: "rent" }
      node_locale: { eq: $language }
    ) {
      internalName
      pageTitle
      sections {
        __typename
        ... on ContentfulSectionText {
          contentful_id
          id
          title
          text {
            raw
          }
        }
        ... on ContentfulSectionRentalForm {
          title
          description {
            raw
          }
          bikeSection {
            __typename
            ... on ContentfulSectionBikeList {
              title
              description {
                raw
              }
              bikes {
                internalName
                model
                brand
                colour
                description {
                  raw
                }
                node_locale
                image {
                  title
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: TRACED_SVG)
                    }
                  }
                }
                sizes
                halfDayPrice
                fullDayPrice
              }
            }
          }
        }
      }
    }
  }
`

export default BikeRentalPage
